<template>
  <div>
    <div
      class="expander grid"
      :class="{
        'grid-rows-[0fr_0fr]': !show,
        'grid-rows-[1fr_0fr]': show,
      }"
    >
      <div
        :class="{
          'overflow-hidden': !show || isAnimating,
        }"
      >
        <slot v-if="noUnloadOnHidden || show || isAnimating" />
      </div>
      <div></div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  modelValue: boolean;
  initialAnimationShow?: boolean;
  duration?: number;
  noUnloadOnHidden?: boolean;
}

interface Emits {
  (e: "update:modelValue", v: boolean): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const show = ref(props.initialAnimationShow ? false : props.modelValue);

const isAnimating = ref(false);
const transitionDuration = ref(`${props.duration ?? 150}ms`);

watch(
  () => props.modelValue,
  (v) => {
    isAnimating.value = true;
    show.value = v;

    setTimeout(() => {
      isAnimating.value = false;
    }, 150);
  },
);

onMounted(() => {
  if (props.initialAnimationShow) show.value = props.modelValue;
});
</script>

<style scoped>
.expander {
  transition-property: grid-template-rows;
  transition-timing-function: ease;
  transition-duration: v-bind(transitionDuration);
}
</style>
